import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { logOut } from "../../../lib/User";
import QuickViewAction from "../../../redux/actions";
import { CONSTANTS } from "../../../helpers/constants";

const Content = ({ location, inboxHeader, setInboxHeader, user }) => {
  const [shouldShowProfile, setShouldShowProfile] = useState(false);
  const dispatch = useDispatch();
  const {
    toggleQuickViewOpen,
    toggleMobileSideBarOpen,
    toggleMobileSideBarClose,
  } = QuickViewAction;
  const [mobileSidebarToggle, setMobileSidebarToggle] = useState(false);

  React.useEffect(() => {
    if (mobileSidebarToggle) {
      dispatch(toggleMobileSideBarOpen());
    } else {
      dispatch(toggleMobileSideBarClose());
    }
  }, [mobileSidebarToggle]);

  if (mobileSidebarToggle) {
    document.body.classList.remove("dashboard");
    document.body.classList.add(
      "mac",
      "desktop",
      "js-focus-visible",
      "sidebar-open",
      "sidebar-visible"
    );
  } else {
    document.body.classList.remove(
      "mac",
      "desktop",
      "js-focus-visible",
      "sidebar-open",
      "sidebar-visible"
    );
    document.body.classList.add("dashboard");
    // console.log(user)
  }

  return user ? (
    <React.Fragment>
      <a
        href="#"
        className="btn-link toggle-sidebar d-lg-none pg-icon btn-icon-link"
        data-toggle="sidebar"
        onClick={() => {
          setMobileSidebarToggle((prevState) => !prevState);
        }}
      >
        menu
      </a>
      <div className="">
        <div className="brand inline">
          <a href="/home">
            <img
              src="/assets/img/doomie/faciam-logo.png"
              alt="logo"
              data-src="/assets/img/faciam-logo.png"
              data-src-retina="/assets/img/logo_2x.png"
              height="30"
              width="80"
            />
          </a>
        </div>
        <a
          aria-label=""
          className="btn btn-info btn-cons m-t-10 col-4 text-sm"
          href={`${CONSTANTS.base_url}api/v1/data/docs/generate/pdf/target/6`}
          download
          style={{ margin: "0 -4rem" }}
        >
          <small>Descargar Tarjetas</small>
        </a>
      </div>
      <div className="d-flex align-items-center">
        <div
          className={`dropdown pull-right d-lg-block d-none ${
            shouldShowProfile ? "show" : ""
          }`}
        >
          <button
            className="profile-dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded={shouldShowProfile ? "true" : "false"}
            aria-label="profile dropdown"
          >
            <span className="thumbnail-wrapper d32 circular inline">
                <img
                  src={user.avatar || `${CONSTANTS.DEFAULT_USER_AVATAR}${user.nombre}`}
                  alt=""
                  width="32"
                  height="32"
                  onClick={() =>
                    setShouldShowProfile((prevState) => !prevState)
                  }
                />
              
            </span>
          </button>
          <div
            style={{ width: "fit-content" }}
            className={`dropdown-menu dropdown-menu-right profile-dropdown ${
              shouldShowProfile ? "show" : ""
            }`}
            role="menu"
          >
            <div className="dropdown-item">
              <span>
                Bienvenido: <br />
                <b>{user.nombre}</b>
              </span>
            </div>
            <div className="dropdown-divider"></div>
            <a href="/user/profile" className="dropdown-item">
              Mi perfil
            </a>
            {/* <div className="dropdown-divider"></div>
            <a href="#" className="dropdown-item">
              Help
            </a>
            <a href="#" className="dropdown-item">
              Settings
            </a> */}
            <div
              onClick={() => {
                logOut();
              }}
              className="dropdown-item"
            >
              Cerrar sesión
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : null;
};

export default Content;
